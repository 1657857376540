import { httpClient } from '~common/plugins/httpClient'
import type {
  IPaginationList,
  IPaginationPayload
} from '~common/models/Pagination'
import type {
  ICreateSwiftWithdrawPayload,
  IWithdrawalSwiftUploadDocResponse,
  IWithdrawalSwiftTemplateDeleteResponse,
  IWithdrawalSwiftTemplate,
  IGetSwiftPayload,
  ISwiftListResponse,
  IPatchSwift,
  ISwiftItem
} from '~/models/Swift'

export default {
  async createSwiftWithdraw(payload: ICreateSwiftWithdrawPayload) {
    return await httpClient.post<ISwiftItem>('/withdrawals/swift', payload)
  },

  async getSwift(id: number) {
    const result = await httpClient.get<ISwiftItem>('/withdrawals/swift/' + id)

    return result.data
  },

  async getSwiftList(payload: IGetSwiftPayload) {
    const result = await httpClient.get<ISwiftListResponse>(
      '/withdrawals/swift',
      {
        params: payload
      }
    )

    return result.data
  },

  async updateSwift(id: number | undefined, payload: IPatchSwift) {
    if (id === undefined) {
      console.error('You must specify the sfift id')

      return
    }

    return await httpClient.patch<ISwiftItem>(
      '/withdrawals/swift/' + id,
      payload
    )
  },

  async uploadDoc(swiftId: number, files: FileList | null) {
    if (!files) {
      return
    }

    const file = files.length > 0 && files[0]

    if (!file) {
      return
    }

    const formData = new FormData()

    formData.append('swfit.doc', file, file.name)

    return await httpClient<IWithdrawalSwiftUploadDocResponse>({
      method: 'post',
      url: `/files/withdrawals/swift/${swiftId}/payment-document`,
      data: formData
    })
  },

  async getWithdrawalSwiftTemplates(
    params: { currency_id?: number } & IPaginationPayload
  ) {
    const result = await httpClient.get<
      IPaginationList<IWithdrawalSwiftTemplate>
    >('/withdrawals/swift-templates', { params })

    return result.data
  },

  async deleteWithdrawalSwiftTemplate(id: number) {
    const result =
      await httpClient.delete<IWithdrawalSwiftTemplateDeleteResponse>(
        `/withdrawals/swift-templates/${id}`
      )

    return result.data
  }
}
