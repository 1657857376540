export default {
  async fetchWithTimeout(resource: string, timeout = 8000) {
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), timeout)

    const response = await fetch(resource, {
      signal: controller.signal
    })

    clearTimeout(id)

    return response
  },

  async getDistributionsManifest(crowdinHash: string) {
    const response = await this.fetchWithTimeout(
      `https://distributions.crowdin.net/${crowdinHash}/manifest.json`,
      1500
    )

    if (!response.ok) {
      return undefined
    }

    return await response.json()
  },

  async getDistributionsContent(
    crowdinHash: string,
    lang: string,
    crowdinFile: string,
    timestamp: string | number | undefined | unknown
  ) {
    if (!timestamp) {
      return undefined
    }

    const response = await this.fetchWithTimeout(
      `https://distributions.crowdin.net/${crowdinHash}/content/${lang}/${crowdinFile}?timestamp=${timestamp}`,
      1500
    )

    if (!response.ok) {
      return undefined
    }

    return await response.json()
  }
}
