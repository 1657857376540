import { AxiosError } from 'axios'

import type { INotify } from '../../models/Notify'
import { notification } from './useRegisterNotify'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import i18n from '../../plugins/i18n'

type TCallableFn = () => void | Promise<void>

export default (onNotifyRegister?: TCallableFn) => {
  const notifySuccess = (message: string, options?: Omit<INotify, 'text'>) => {
    notification?.value?.success({ text: message, ...options })
  }

  const router = useRouter()

  const notifyError = (error: unknown, options?: Omit<INotify, 'text'>) => {
    const { t } = i18n.global

    if (!__IS_NUXT__ && error instanceof AxiosError) {
      if (
        error.code === 'InsufficientBalance' ||
        error.code === 'NotEnoughMoneyOnBalance' ||
        error.code === 'BorrowingPledgeAmountGreaterThenBalance'
      ) {
        options = {
          ...options,
          timeout: Infinity,
          btnText: t('common.wallet.depositPageTitle'),
          closeOnClick: true,
          handlerClick() {
            router
              .push({
                name: 'deposit'
              })
              .then()
          }
        }
      } else if (
        error.code === 'NeedCompleteKYC' ||
        error.code === 'NeedCompleteKYCKgz'
      ) {
        options = {
          ...options,
          timeout: Infinity,
          btnText: t('common.notify.NeedCompleteKYCBtnTitle'),
          closeOnClick: true,
          handlerClick() {
            router
              .push({
                name: 'profile-kyc'
              })
              .then()
          }
        }
      } else if (error.code === 'NeedFillNickname') {
        options = {
          ...options,
          timeout: Infinity,
          btnText: t('common.notify.NeedUpdateNicknameBtnTitle'),
          closeOnClick: true,
          handlerClick() {
            router
              .push({
                name: 'profile-personal'
              })
              .then()
          }
        }
      }
    }

    const notStringError =
      !error || (!(error instanceof AxiosError) && !(error instanceof Error))
        ? 'Unknown error'
        : error.message

    const message = typeof error === 'string' ? error : notStringError

    notification?.value?.error({ text: message, ...options })
  }

  watch(
    notification,
    val => {
      if (val !== undefined && onNotifyRegister) {
        onNotifyRegister()
      }
    },
    { immediate: true }
  )

  return {
    notify: notification,
    notifySuccess,
    notifyError
  }
}
