<template>
  <AppLoader :is-showing="isLoadingFirstData" />
  <RouterView class="main-content default-scroll overflow-x-hidden" />

  <AppModals />

  <AppNotifications ref="notification" />
</template>

<script setup lang="ts">
import { watch, toRef } from 'vue'
import { useRouter, useRoute, RouterView } from 'vue-router'

import AppNotifications from '~common/components/AppNotifications.vue'
import AppModals from '~common/components/AppModals.vue'

import { useAuthStore } from '~common/stores/auth'
import { useWebsocketStore } from '~common/stores/websocket'
import useExchangeRates from '~common/stores/exchangeRates'

import { useI18n } from '~common/composables/useI18n'
import { useRegisterNotify } from '~common/composables/notify'
import { useReferral } from '~common/composables/useReferral'

//import { useFavicon } from '@vueuse/core'
import type { TSubscribed } from '~common/models/Websocket'
import { useNotificationStore } from '~/stores/useNotification'

import debounce from '~common/utils/debounce'

import type { IAuthUserBalance } from '~common/models/AuthUser'
import useOrderNotify from '~/composables/useOrderNotify'
import useP2PUnreadDeals from '~/composables/p2p/useP2PUnreadDeals'
import useSwiftList from '~/stores/useSwiftList'
import type { SSwiftStatus } from '~/models/Swift'
import { storeToRefs } from 'pinia'
import AppLoader from '~common/components/AppLoader.vue'
import useGuardState from '~common/composables/useGuardState'

import 'virtual:pwa-register/vue'
import useNewVersionNotify from '~common/composables/useNewVersionNotify'
import useUiSettingsStore from './common/src/stores/uiSettings'
import useTheme from '~/composables/useTheme'
import useSettings from './composables/useSettings'

/* ------------------------------------------------------------------------------------------------------------------ */

// необходимо две подписки, так как ивенты по p2p сделкам по другому обрабатываются
let userOtherEventsSocket: TSubscribed
let userDealsEventsSocket: TSubscribed
let swiftStatus: SSwiftStatus

const authStore = useAuthStore()
const { isLoadingFirstData } = storeToRefs(authStore)

useGuardState()

useNotificationStore()
useUiSettingsStore()
useExchangeRates()
useSettings()
useTheme()

const swiftList = useSwiftList()

const router = useRouter()
const route = useRoute()
const { notification } = useRegisterNotify()
const { t } = useI18n()
const { subscribe, unsubscribe } = useWebsocketStore()
const { showOrderMessage } = useOrderNotify()
const referral = useReferral()

subscribe(
  'public',
  ({ event, data }) => {
    switch (event) {
      case 'app_on_maintenance':
        authStore.user.is_maintenance = data.on_maintenance
        break
    }
  },
  true
)

if (import.meta.env.MODE !== 'development') {
  watch(toRef(authStore.user, 'is_maintenance'), val => {
    if (val) {
      router.push({ name: 'technical-works' }).then()
    } else {
      window.location.replace('/')
    }
  })
}

useNewVersionNotify('app.Version.title', 'common.Version.btnTitleLoadNewVer')

const { addUnreadDealId, uniqUnreadDealsIds, removeUnreadDealsCookies } =
  useP2PUnreadDeals()

watch(
  () => authStore.isAuthenticated,
  async val => {
    if (!val) {
      unsubscribeUserEvents()

      return
    }

    subscribeToUserEvents()
    handleRefarral()
  },
  { immediate: true }
)

// хак, чтобы обрабатывать через роутер клики на ссылки, которые приходят из переводов
// и имеют аттрибут self. Важно так как router-link в переводы нормально не вставишь
window.document.body.addEventListener(
  'click',
  (e: MouseEvent | PointerEvent) => {
    const target = e.target as HTMLAnchorElement

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (target.tagName.toLowerCase() === 'a' && target.attributes.self) {
      router.push(target.pathname + target.search + target.hash)

      return true
    }
  },
  true
)

function subscribeToUserEvents() {
  userOtherEventsSocket = subscribe('user', ({ event, data }) => {
    if (
      event.indexOf('deal') >= 0 &&
      // если не открыта вкладка и страница с p2p-сделкой по которой прилетел ивент
      (document.visibilityState === 'hidden' ||
        +route.params?.id !== data.data.id)
    ) {
      addUnreadDealId(data.data.id)
    }

    switch (event) {
      case 'change_balance':
        authStore.updateUserBalances(
          data.data.map((b: IAuthUserBalance) => ({
            available: b.available,
            blocked: b.blocked,
            pending: b.pending,
            symbol: b.currency_symbol,
            is_default: b.is_default
          }))
        )
        break
      case 'deposit_confirmed':
        notification.value?.success({
          text: t('app.ExchangePage.depositCompleted'),
          timeout: 12000
        })
        break
      case 'order_created':
        showOrderMessage(
          data.data.type === 'market'
            ? 'app.ExchangePage.orderSuccessCompleted'
            : 'app.ExchangePage.orderCreateSuccessfully',
          data.data
        )
        break
      case 'order_created_bot_reminder':
        showOrderMessage(
          data.data.type === 'market'
            ? 'app.ExchangePage.orderSuccessCompleted'
            : 'app.ExchangePage.orderCreateSuccessfully',
          data.data
        )

        notification.value?.success({
          text: t('app.ExchangePage.watchWithBot.info'),
          timeout: Infinity,
          closeOnClick: true,
          btnText: t('app.ExchangePage.watchWithBot.connect'),
          handlerClick() {
            router.push('/profile/notifications')
          }
        })
        break
      case 'order_deleted':
        showOrderMessage(
          'app.ExchangePage.orderCancelledSuccessfully',
          data.data
        )
        break
      case 'order_completed':
        showOrderMessage('app.ExchangePage.orderSuccessCompleted', data.data)
        break
      case 'order_partially_completed':
        showOrderMessage(
          'app.ExchangePage.orderSuccessPartiallyCompleted',
          data.data
        )
        break
      case 'withdrawal_cancelled':
        notification.value?.error({
          text: t('app.notifications.withdrawalCancelled', {
            amount: data.data?.amount,
            symbol: data.data?.currency_symbol
          }),
          timeout: 12000
        })
        break
      case 'withdrawal_updated':
        /*
          data.data.channel - visa/swift/cash/crypto
         */
        switch (data.data.channel) {
          case 'swift':
            swiftStatus = data.data.status as SSwiftStatus
            swiftList.refresh()

            switch (swiftStatus) {
              case 'canceled':
              case 'completed':
              case 'pending':
              case 'sended_by_bank':
              case 'sended_to_bank':
                swiftList.notifyStatusChange(swiftStatus)
                break
            }
            break
        }
        break
    }
  })

  userDealsEventsSocket = subscribe(
    'user',
    // debounce чтоб не спамить нотификациями по p2p сделкам
    debounce(({ event, data }) => {
      const notifyDealMessage = (translateKey: string, id?: number) => {
        notification.value?.success({
          text: t(`common.p2p.Notifications.${translateKey}`, { id }),
          timeout: 12000
        })
      }

      switch (event) {
        case 'deal_created':
          notifyDealMessage('dealCreated', data.data.id)
          break
        case 'deal_updated':
          notifyDealMessage('dealUpdated', data.data.id)
          break
        case 'deal_confirmed':
          notifyDealMessage('dealConfirmed', data.data.id)
          break
        case 'deal_message': {
          if (data.data?.action?.is_mine) break

          notifyDealMessage('dealMessage', data.data.id)
          break
        }
        case 'deal_completed':
          notifyDealMessage('dealCompleted', data.data.id)
          break
        case 'deal_cancelled':
          notification.value?.error({
            text: t('common.p2p.Notifications.dealCancelled', {
              id: data.data.id
            }),
            timeout: 12000
          })
          break
      }
    }, 200)
  )
}

function unsubscribeUserEvents() {
  unsubscribe(userOtherEventsSocket)
  unsubscribe(userDealsEventsSocket)
}

const handleRefarral = () => {
  if (referral.getCode()) {
    referral.setCode('')
  }
}

watch(
  uniqUnreadDealsIds,
  val => {
    if (!val.length) {
      removeUnreadDealsCookies()
    }

    // TODO !!! useFavicon ломает иконки на ios, macOS (сафари). Нужно будет искать замену

    // let faviconPath: string
    //
    // if (import.meta.env.MODE !== 'production') {
    //   faviconPath = val.length
    //     ? `/monthly-static/favicon-notify.${import.meta.env.MODE}.svg`
    //     : `/monthly-static/favicon.${import.meta.env.MODE}.svg`
    // } else {
    //   faviconPath = val.length
    //     ? '/monthly-static/favicon-notify.svg'
    //     : '/favicon.svg'
    // }
    //
    // useFavicon(faviconPath)
  },
  { immediate: true }
)
</script>
