import { client } from '../plugins/platform'

export function wlHostname(
  hostname: string,
  rollback = 'app.dev.bitbanker.org'
) {
  return __IS_ADMIN_PANEL__ ||
    import.meta.env.MODE === 'development' ||
    client.is.capacitor
    ? rollback
    : hostname
}
